import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-tabulator/lib/styles.css';
// import './tabulator_bootstrap5.css';
// import 'react-tabulator/lib/css/tabulator_midnight.min.css'; // theme
// import 'react-tabulator/lib/css/tabulator_simple.min.css';
// import 'tabulator-tables/dist/css/tabulator_midnight.min.css'
import 'tabulator-tables/dist/css/tabulator_simple.min.css'
// import 'tabulator-tables/dist/css/tabulator.min.css'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import AppCohort from './AppCohort';
import reportWebVitals from './reportWebVitals';
import {DateTime} from 'luxon'
import AppCohort2 from './AppCohort2';
window.DateTime = DateTime

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/cohort_test",
    element: <AppCohort />,
  },
  {
    path: "/cohort_test2",
    element: <AppCohort2 />,
  },
  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
