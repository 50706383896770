import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { DateTime } from "luxon";
import _ from "lodash";
import { ReactTabulator } from 'react-tabulator'
// import MultiSelectEditor from 'react-tabulator/lib/editors/MultiSelectEditor'

function AppCohort2() {
  const initialized = useRef(false);
  const tabulatorRef = useRef(null);

  const [data, setData] = useState([]);
  // const [groupingEnabled, setGroupingEnabled] = useState(true);
  const [groupingEnabled, setGroupingEnabled] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  let startDate = DateTime.now().minus({ days: 7 });
  const [dateRange, setDateRange] = useState([startDate, null]);

  function minMaxFilterFunction(headerValue, rowValue, rowData, filterParams){
    //headerValue - the value of the header filter element
    //rowValue - the value of the column in this row
    //rowData - the data for the row being filtered
    //filterParams - params object passed to the headerFilterFuncParams property

        if(rowValue){
            if(headerValue.start != ""){
                if(headerValue.end != ""){
                    return rowValue >= headerValue.start && rowValue <= headerValue.end;
                }else{
                    return rowValue >= headerValue.start;
                }
            }else{
                if(headerValue.end != ""){
                    return rowValue <= headerValue.end;
                }
            }
        }

    return true; //must return a boolean, true if it passes the filter.
}
  var minMaxFilterEditor = function(cell, onRendered, success, cancel, editorParams){

    var end;

    var container = document.createElement("span");

    //create and style inputs
    var start = document.createElement("input");
    start.setAttribute("type", "number");
    start.setAttribute("placeholder", "Min");
    start.setAttribute("min", 0);
    start.setAttribute("max", 100);
    start.style.padding = "4px";
    start.style.width = "50%";
    start.style.boxSizing = "border-box";

    start.value = cell.getValue();

    function buildValues(){
        success({
            start:start.value,
            end:end.value,
        });
    }

    function keypress(e){
        if(e.keyCode == 13){
            buildValues();
        }

        if(e.keyCode == 27){
            cancel();
        }
    }

    end = start.cloneNode();
    end.setAttribute("placeholder", "Max");

    start.addEventListener("change", buildValues);
    start.addEventListener("blur", buildValues);
    start.addEventListener("keydown", keypress);

    end.addEventListener("change", buildValues);
    end.addEventListener("blur", buildValues);
    end.addEventListener("keydown", keypress);


    container.appendChild(start);
    container.appendChild(end);

    return container;
 }

  const columns = [
    // { title: 'Event date time', field: 'iso_date', formatter:"datetime" },
    // {
    //   title: 'Last visit', hozAlign: 'right', field: 'last_visit_date', formatter: "datetime",
    //   sorter: "date",
    //   formatterParams: {
    //     inputFormat: "yyyy-MM-dd HH:ss",
    //     outputFormat: "dd/MM/yy",
    //     invalidPlaceholder: "(invalid date)",
    //     // timezone:"America/Los_Angeles",
    //   },
    //   // headerFilter: 'datetime',
    // },    
    // {
    //   title: 'NHS number', hozAlign: 'right', field: 'nhs_number',
    //   headerFilter: 'input',
    // },
    // {
    //   title: 'Hospital number', hozAlign: 'right', field: 'hospital_id',
    //   headerFilter: 'input',
    // },
    {
      title: 'Full Name', hozAlign: 'right', field: 'Full Name',
      headerFilter: 'input',
    },
    {
      title: 'Gender', hozAlign: 'right', field: 'Gender',
      headerFilter: 'list',
      headerFilterParams: { valuesLookup: true, clearable: true },
    },
    {
      title: 'Age', hozAlign: 'right', field: 'age',
      // headerFilter: 'input',
      headerFilter:minMaxFilterEditor, headerFilterFunc:minMaxFilterFunction, headerFilterLiveFilter:false

    },
    {
      title: 'Date of birth', hozAlign: 'right', field: 'dob', formatter: "datetime",
      sorter: "date",
      formatterParams: {
        inputFormat: "yyyy-MM-dd HH:ss",
        outputFormat: "dd/MM/yy",
        invalidPlaceholder: "(invalid date)",
        // timezone:"America/Los_Angeles",
      },
      headerFilter: 'date',
    },
    {
      title: 'Position', hozAlign: 'right', field: 'Position',
      headerFilter: 'list',
      headerFilterParams: { valuesLookup: true, clearable: true },
    },
    
    {
      title: 'Visual impairment', hozAlign: 'left', field: 'Certificate of Visual Impairment',
      // headerFilter: 'input',
      formatter:"tickCross", hozAlign: 'center',  headerFilter:"tickCross",  headerFilterParams:{"tristate":true},headerFilterEmptyCheck:function(value){return value === null}
    },
    {
      title: 'Address', hozAlign: 'left', field: 'Address',
      headerFilter: 'input',
    },
    {
      title: 'Email', hozAlign: 'right', field: 'Email',
      headerFilter: 'input',
    },
    {
      title: 'Salary', hozAlign: 'right', field: 'Salary',
      // headerFilter: 'input',
    },

    // {
    //   title: 'Event name', hozAlign: 'center', field: 'event_name',
    //   headerFilter: 'list',
    //   headerFilterParams: { valuesLookup: true, clearable: true },
    //   // headerFilter: MultiSelectEditor,
    //   // headerFilterParams: { values: colorOptions } 
    // },
    // { title: 'Record type', field: 'record_type', headerFilter: 'input' },
    // {
    //   title: 'Repeated fails', field: 'failed_attempts', headerFilter: 'number',
    //   headerFilterParams: { clearable: true },
    // },
    // { title: 'Person accessed', field: 'person_accessed', headerFilter: 'input' },

    // { title: 'Name', field: 'name', width: 150 },
    // { title: 'Age', field: 'age', hozAlign: 'left', formatter: 'progress' },
    // { title: 'Favourite Color', field: 'color' },
    // { title: 'Date Of Birth', field: 'dob', sorter: 'date' },
    // { title: 'Rating', field: 'rating', hozAlign: 'center', formatter: 'star' },
    // { title: 'Passed?', field: 'passed', hozAlign: 'center', formatter: 'tickCross' },
    // { title: 'Custom', field: 'custom', hozAlign: 'center', editor: 'input', formatter: reactFormatter(<SimpleButton />) }
  ];
  // const data2 = [
  //   { id: 1, name: 'Oli Bob', age: '12', color: 'red', dob: '01/01/1980', rating: 5, passed: true, pets: ['cat', 'dog'] },
  //   { id: 2, name: 'Mary May', age: '1', color: 'green', dob: '12/05/1989', rating: 4, passed: true, pets: ['cat'] },
  //   { id: 3, name: 'Christine Lobowski', age: '42', color: 'green', dob: '10/05/1985', rating: 4, passed: false },
  //   { id: 4, name: 'Brendon Philips', age: '125', color: 'red', dob: '01/08/1980', rating: 4.5, passed: true },
  //   { id: 5, name: 'Margret Marmajuke', age: '16', color: 'yellow', dob: '07/01/1999', rating: 4, passed: false },
  //   {
  //     id: 6,
  //     name: 'Van Ng',
  //     age: '37',
  //     color: 'green',
  //     dob: '06/10/1982',
  //     rating: 4,
  //     passed: true,
  //     pets: ['dog', 'fish']
  //   },
  //   { id: 7, name: 'Duc Ng', age: '37', color: 'yellow', dob: '10/10/1982', rating: 4, passed: true, pets: ['dog'] }
  // ];

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      // console.log("once ");

      fetch("hr_records_iso.json")
        .then((response) => response.json())
        .then((data) => {
          // setTodo(data);
          // setLoading(false);
          _.forEach(data, item => {
            // item.iso_date = item.date_and_time;
            // item.date_and_time = DateTime.fromISO(item.date_and_time);
            // item.last_visit_date = DateTime.fromISO(item.last_visit_date);
            item.dob = DateTime.fromISO(item["Date of Birth"]);
            item.age = Math.floor(DateTime.now().diff(item.dob, 'years').toObject().years);
            item.contract_date = DateTime.fromISO(item["Contract Date"]);
          });
          // data = _.sortBy(data, (item) => item.last_visit_date.toJSDate());

          // // Paracetamol
          // _.forEach(data, item => {
          //   let isThere = false;
          //   _.forEach(item.medical_history, med => {
          //     if (med === "Paracetamol") {
          //       isThere = true;
          //     }
          //   });
          //   if (!isThere) {
          //     item.medical_history.push("Paracetamol");
          //   }  
          // });

          // _.forEach(data, item => {
          //   let isThere = false;
          //   _.forEach(item.diagnosis, med => {
          //     if (med === "Cardiac Arrest") {
          //       isThere = true;
          //     }
          //   });
          //   if (isThere) {
          //     item.diagnosis = ["Cardiac Arrest"];
          //   }  
          // });

          setData(data);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    function customFilter(data, filterParams) {
      let status = true;

      if (filterParams.start) {
        if (data.date_and_time < filterParams.start) {
          status = false;
        }
        // console.log(data, filterParams);
      }
      if (filterParams.end) {
        if (data.date_and_time > filterParams.end) {
          status = false;
        }
      }

      return status;
    }

    if (tabulatorRef.current) {
      // console.log("tabulatorRef", tabulatorRef.current);

      // tabulatorRef.current.setFilter("date_and_time", ">", dateRange[0]);
      // tabulatorRef.current.addFilter("date_and_time", "=", dateRange[0]);
      tabulatorRef.current.addFilter(customFilter, { start: dateRange[0], end: dateRange[1] });
    }

    return () => {
      if (tabulatorRef.current) {
        tabulatorRef.current.clearFilter(false);
      }
    }
  }, [dateRange, dataLoaded]);

  const options = {
    pagination: true,
    paginationSize: 15,
    paginationSizeSelector: [5, 10, 15, 20],
    movableColumns: true,
    paginationCounter: "rows",
    // groupBy:"Position",
    initialSort:[
      { column: "last_visit_date", dir: "desc" },
    ]
  }

  const handleChange = (isStart) => (value) => {
    if (isStart) {
      setDateRange([DateTime.fromISO(value.target.value), dateRange[1]]);
    } else {
      setDateRange([dateRange[0], DateTime.fromISO(value.target.value)]);
    }
  }

  const groupingSwitch = () => {
    if (groupingEnabled) {
      tabulatorRef.current.setGroupBy(null);
    } else {
      tabulatorRef.current.setGroupBy("diagnosis");
    }
      
    setGroupingEnabled(!groupingEnabled);
  }

  return (
    <div className="App">

      <div>
        <div className='group-enabled' onClick={() => {
          // tabulatorRef.current.download()
          tabulatorRef.current.download("xlsx", "data.xlsx", {sheetName:"ExampleData"}); 
        }}>Download data to excel</div>

      </div>
      {/* <div className="event-dates">
        <div className='event-dates-input' style={{padding: 20, fontSize: 12}}>
          {groupingEnabled && 
          <div className='group-enabled' onClick={groupingSwitch}>
            Grouping enabled, click here to disable.
          </div>
          }
          {!groupingEnabled && 
          <div className='group-disabled' onClick={groupingSwitch}>
            Grouping disabled, click here to enable.
          </div>
          }
        </div>
      </div> */}
      {/* <div className="event-dates">
        <div className='event-dates-input'>
          <div className='event-dates-input-label'>Event start date</div>
          <input type='date' value={dateRange[0] ? dateRange[0].toFormat('yyyy-LL-dd') : ""} onChange={handleChange(true)} />
        </div>

        <div className='event-dates-input'>
          <div className='event-dates-input-label'>Event end date</div>
          <input type='date' value={dateRange[1] ? dateRange[1].toFormat('yyyy-LL-dd') : ""} onChange={handleChange(false)} />
        </div>
      </div> */}

      <ReactTabulator
        columns={columns}
        options={options}
        data={data}
        tooltips={true}
        layout={"fitColumns"}
        // ref={tabulatorRef}
        events={{
          dataLoaded: function (data) {
            setDataLoaded(data.length > 0);
          },
          tableBuilt: function () {
            // tabulatorRef.current.setSort([
            //   { column: "last_visit_date", dir: "desc" },
            // ]);
          }
        }}
        onRef={(r) => (tabulatorRef.current = r.current)}
      />
    </div>
  );
}

export default AppCohort2;
