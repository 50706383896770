import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { DateTime } from "luxon";
import _ from "lodash";
import { ReactTabulator } from 'react-tabulator'
// import MultiSelectEditor from 'react-tabulator/lib/editors/MultiSelectEditor'

function App() {
  const initialized = useRef(false);
  const tabulatorRef = useRef(null);

  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  let startDate = DateTime.now().minus({ days: 7 });
  const [dateRange, setDateRange] = useState([startDate, null]);

  const columns = [
    // { title: 'Event date time', field: 'iso_date', formatter:"datetime" },
    {
      title: 'Event date time', hozAlign: 'right', field: 'date_and_time', formatter: "datetime",
      // headerFilter: 'datetime',
    },
    {
      title: 'Event name', hozAlign: 'center', field: 'event_name',
      headerFilter: 'list',
      headerFilterParams: { valuesLookup: true, clearable: true },
      // headerFilter: MultiSelectEditor,
      // headerFilterParams: { values: colorOptions } 
    },
    { title: 'Record type', field: 'record_type', headerFilter: 'input' },
    {
      title: 'Repeated fails', field: 'failed_attempts', headerFilter: 'number',
      headerFilterParams: { clearable: true },
    },
    { title: 'Person accessed', field: 'person_accessed', headerFilter: 'input' },

    // { title: 'Name', field: 'name', width: 150 },
    // { title: 'Age', field: 'age', hozAlign: 'left', formatter: 'progress' },
    // { title: 'Favourite Color', field: 'color' },
    // { title: 'Date Of Birth', field: 'dob', sorter: 'date' },
    // { title: 'Rating', field: 'rating', hozAlign: 'center', formatter: 'star' },
    // { title: 'Passed?', field: 'passed', hozAlign: 'center', formatter: 'tickCross' },
    // { title: 'Custom', field: 'custom', hozAlign: 'center', editor: 'input', formatter: reactFormatter(<SimpleButton />) }
  ];
  // const data2 = [
  //   { id: 1, name: 'Oli Bob', age: '12', color: 'red', dob: '01/01/1980', rating: 5, passed: true, pets: ['cat', 'dog'] },
  //   { id: 2, name: 'Mary May', age: '1', color: 'green', dob: '12/05/1989', rating: 4, passed: true, pets: ['cat'] },
  //   { id: 3, name: 'Christine Lobowski', age: '42', color: 'green', dob: '10/05/1985', rating: 4, passed: false },
  //   { id: 4, name: 'Brendon Philips', age: '125', color: 'red', dob: '01/08/1980', rating: 4.5, passed: true },
  //   { id: 5, name: 'Margret Marmajuke', age: '16', color: 'yellow', dob: '07/01/1999', rating: 4, passed: false },
  //   {
  //     id: 6,
  //     name: 'Van Ng',
  //     age: '37',
  //     color: 'green',
  //     dob: '06/10/1982',
  //     rating: 4,
  //     passed: true,
  //     pets: ['dog', 'fish']
  //   },
  //   { id: 7, name: 'Duc Ng', age: '37', color: 'yellow', dob: '10/10/1982', rating: 4, passed: true, pets: ['dog'] }
  // ];

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      // console.log("once ");

      fetch("data.json")
        .then((response) => response.json())
        .then((data) => {
          // setTodo(data);
          // setLoading(false);
          _.forEach(data, item => {
            item.iso_date = item.date_and_time;
            item.date_and_time = DateTime.fromISO(item.date_and_time);
          });
          data = _.sortBy(data, (item) => item.date_and_time.toJSDate());
          setData(data);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    function customFilter(data, filterParams) {
      let status = true;

      if (filterParams.start) {
        if (data.date_and_time < filterParams.start) {
          status = false;
        }
        // console.log(data, filterParams);
      }
      if (filterParams.end) {
        if (data.date_and_time > filterParams.end) {
          status = false;
        }
      }

      return status;
    }

    if (tabulatorRef.current) {
      // console.log("tabulatorRef", tabulatorRef.current);

      // tabulatorRef.current.setFilter("date_and_time", ">", dateRange[0]);
      // tabulatorRef.current.addFilter("date_and_time", "=", dateRange[0]);
      tabulatorRef.current.addFilter(customFilter, { start: dateRange[0], end: dateRange[1] });
    }

    return () => {
      if (tabulatorRef.current) {
        tabulatorRef.current.clearFilter(false);
      }
    }
  }, [dateRange, dataLoaded]);

  const options = {
    pagination: true,
    paginationSize: 15,
    paginationSizeSelector: [5, 10, 15, 20],
    movableColumns: true,
    paginationCounter: "rows",
  }

  const handleChange = (isStart) => (value) => {
    if (isStart) {
      setDateRange([DateTime.fromISO(value.target.value), dateRange[1]]);
    } else {
      setDateRange([dateRange[0], DateTime.fromISO(value.target.value)]);
    }
  }

  return (
    <div className="App">

      <div className="event-dates">
        <div className='event-dates-input'>
          <div className='event-dates-input-label'>Event start date</div>
          <input type='date' value={dateRange[0] ? dateRange[0].toFormat('yyyy-LL-dd') : ""} onChange={handleChange(true)} />
        </div>

        <div className='event-dates-input'>
          <div className='event-dates-input-label'>Event end date</div>
          <input type='date' value={dateRange[1] ? dateRange[1].toFormat('yyyy-LL-dd') : ""} onChange={handleChange(false)} />
        </div>
      </div>

      <ReactTabulator
        columns={columns}
        options={options}
        data={data}
        tooltips={true}
        layout={"fitColumns"}
        // ref={tabulatorRef}
        events={{
          dataLoaded: function (data) {
            setDataLoaded(data.length > 0);
          }
        }}
        onRef={(r) => (tabulatorRef.current = r.current)}
      />
    </div>
  );
}

export default App;
